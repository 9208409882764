// resumeDataSlice.js

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  //----------get-started page---------
  id: "",
  jd_url: "",
  industry: "",
  is_basic_job: false,
  job_title: {
    id: "",
    title: "",
    user_generated: false,
  },

  //----------job title page---------
  // job title in this page is same as job_title above
  roles: "",
  skills: [],
  keywords: [],

  //----------job details page----------
  company: {
    id: "",
    name: "",
  },
  employment_mode: [],
  job_location: {
    id: "",
    name: "",
    state: "",
    country: "",
  },
  job_type: [],
  vacancies: 0,
  job_space: {
    id: "",
    title: "",
  },
  from_location_only: false,
  include_relocation: false,

  //----------preferred candidate qualification page---------
  qualification_level: "",
  qualification: {
    id: "",
    name: "",
  },
  field_of_study: {
    id: "",
    name: "",
  },

  field_of_study_pref: false,
  salary_type: "",

  currency: {
    id: "7b321ae2-a821-4064-b841-d4e8d6840090",
    country_id: "af6432c2-2800-4029-9014-786d8063d885",
    code: "INR",
  },

  min_expected_salary: {
    id: "",
    salary: "",
  },

  max_expected_salary: {
    id: "",
    salary: "",
  },

  expected_salary: {
    visibility: false,
  },
  
  required_exp: {
    min: 0,
    max: 0,
  },
  availability: 0,
  // languages: [
  //     {
  //         "id": "",
  //         "language": "",
  //         "proficiency": ""
  //     }
  // ],
  // certifications: [
  //     "",
  //     ""
  // ],

  //----------miscellaneous---------
  skillsWeightage: null,
  experienceWeightage: null,
  // created_at: "",
  // closing_date: "",
  active: true,
  is_private: null,
  is_draft: false,
  
  // field_weights: {
  //   experience: 35,
  //   skills: 65,
  // },
};

const postJobDataSlice = createSlice({
  name: "postJobData",
  initialState,
  reducers: {
    setPostJobData: (state, action) => {
      return action.payload;
    },
    setGetStartedData: (state, action) => {
      state.id = action.payload.id;
      state.jd_url = action.payload.jd_url;
      state.industry = action.payload.industry;
      state.job_title = action.payload.job_title;
      state.is_basic_job = action.payload.is_basic_job
    },
    setJobTitlePageData: (state, action) => {
      state.job_title = action.payload.job_title;
      state.roles = action.payload.roles_responsibilities;
      state.skills = action.payload.skills;
      state.keywords = action.payload.keywords;
    },
    setJobDetailsPage: (state, action) => {
      state.company = action.payload.company;
      state.employment_mode = action.payload.employment_mode;
      state.job_location = action.payload.job_location;
      state.job_type = action.payload.job_type;
      state.vacancies = action.payload.vacancies;
      state.job_space = action.payload.job_space;
      state.from_location_only = action.payload.from_location_only;
      state.include_relocation = action.payload.include_relocation;
    },
    setPreferredCandidatePage: (state, action) => {
      state.qualification_level = action.payload.qualification_level;
      state.qualification = action.payload.qualification;
      state.field_of_study = action.payload.field_of_study;
      state.salary_type = action.payload.salary_type;
      state.currency = action.payload.currency;
      state.expected_salary = action.payload.expected_salary;
      state.min_expected_salary = action.payload.min_expected_salary;
      state.max_expected_salary = action.payload.max_expected_salary;
      state.required_exp = action.payload.required_exp;
      state.availability = action.payload.availability;
      state.field_of_study_pref = action.payload.field_of_study_pref;
    },

    setAlgorithm: (state, action) => {
      state.skillsWeightage = action.payload.skillsWeightage;
      state.experienceWeightage = action.payload.experienceWeightage;
      // state.field_weights = action.payload.field_weights
    },

    setActivePrivateDraft: (state, action) => {
      state.active = action.payload.active;
      state.is_private = action.payload.is_private;
      state.is_draft = action.payload.is_draft;
    },

    clearData: (state) => {
      // Reset state to initial values
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key];
      });
    },
  },
});

export const {
  setPostJobData,
  setGetStartedData,
  setJobTitlePageData,
  setJobDetailsPage,
  setPreferredCandidatePage,
  setAlgorithm,
  setActivePrivateDraft,
  clearData,
} = postJobDataSlice.actions;
export const selectPostJobData = (state) => state.postJobData;
export default postJobDataSlice.reducer;
