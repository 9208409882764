import { useNavigate } from "react-router-dom";

const DropDownMenu = () => {
  const navigate = useNavigate();

  const togglePath = (path) => {
    navigate(path);
  };

  return (
    <div className="bg-[#FFFFFF] w-[600px] h-[250px] flex justify-center items-center absolute top-14 p-2 rounded-[18px] border-[1px] border-[#D9D9D9]">
      <div className="w-full min-w-[400px] grid grid-cols-2 gap-4">
        <div
          onClick={() => togglePath("/employer")}
          className="hover:bg-fr p-4 rounded-md cursor-pointer"
        >
          <p className="text-[18px] font-outfit font-semibold leading-[27px] text-[#424242]">
            Post a Job Opening
          </p>
          <p className="text-[14px] font-normal leading-[21px] font-outfit text-[#A2A2A2]">
            Our AI algorithms will connect you with relevant applicants in seconds
          </p>
        </div>
        <div
          onClick={() => togglePath("/avua-pool-page")}
          className="hover:bg-fr p-4 rounded-md cursor-pointer"
        >
          <p className="text-[18px] font-outfit font-semibold leading-[27px] text-[#424242]">
            avua Pool
          </p>
          <p className="text-[14px] font-normal leading-[21px] font-outfit text-[#A2A2A2]">
            Unlimited access to our premium talent database
          </p>
        </div>
        <div className="hover:bg-fr p-4 rounded-md">
          <div className="flex items-center space-x-2 whitespace-nowrap">
            <p className="text-[18px] font-outfit font-semibold leading-[27px] text-[#424242]">
              AI Interviews
            </p>
            <div className="w-fit h-fit bg-[#E7D4FF]">
              <p className="text-caption text-[#6B3BAB] font-bold px-1">
                coming soon
              </p>
            </div>
          </div>
          <p className="text-[14px] font-normal leading-[21px] font-outfit text-[#A2A2A2]">
            Streamline the interview process with our intelligent AI
          </p>
        </div>
        <div className="hover:bg-fr p-4 rounded-md">
          <div className="flex items-center space-x-2 whitespace-nowrap">
            <p className="text-[18px] font-outfit font-semibold leading-[27px] text-[#424242]">
             Similar CV Analysis
            </p>
            <div className="w-fit h-fit bg-[#E7D4FF]">
              <p className="text-caption text-[#6B3BAB] font-bold px-1">
                coming soon
              </p>
            </div>
          </div>
          <p className="text-[14px] font-normal leading-[21px] font-outfit text-[#A2A2A2]">
            Upload the CV of your ideal candidate and discover matching profiles
          </p>
        </div>
      </div>
    </div>
  );
};

export default DropDownMenu;
