import { createSlice } from '@reduxjs/toolkit';
import { Login } from '../../api/services';
import { signUp } from '../../api/services';

const initialState = {
  user: null, // This can be an object representing the authenticated user
  isAuthenticated: false,
  isNewUser : false,
  token: '',
  loading: false,
  error: null,
  userType: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action) => {
      console.log('Redux set user : ',state, action.payload)
      state.user = action.payload;
      state.isAuthenticated = action.payload.isAuthenticated?action.payload.isAuthenticated:true
      state.token = action.payload.token;
      state.loading = false;
      state.error = null;
      state.isNewUser = action.payload.is_new_user
      state.userType= action.payload.userType
    },
    clearUser: (state) => {
      state.user = null;
      state.isAuthenticated = false;
      state.loading = false;
      state.error = null;
      state.isNewUser = false
      state.token=""
      state.userType=""
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const { setUser, clearUser, setLoading, setError } = authSlice.actions;

// Thunk action to log in the user
export const loginThunkRequest = (email, password) => async (dispatch) => {
  console.log('login user info : ',email,password)
  try {
    // API call to log in the user
    const user = await Login(email, password);
    
    dispatch(setUser(user))
    
  } catch (error) {
    dispatch(setError('Login failed'));
    return error
  }
};

// Thunk action to sign up the user
export const signupThunkRequest = (userData) => async (dispatch) => {
  console.log('sign up user info : ',userData)
  try {
    const user = await signUp(userData);
    dispatch(setUser(user))

  } catch (error) {
    // Handle API call errors
    dispatch(setError('Signup failed'));
    return error;
  }
};



// Thunk action to log out the user
export const logout = () => async (dispatch) => {
  // API call to log out the user
  await LogoutFunction();

  dispatch(clearUser());
};

// Thunk action to check if the user is already authenticated (e.g., on page refresh)
export const checkAuthentication = () => async (dispatch) => {
  const user = await AuthenticationCheckFunction();

  if (user) {
    dispatch(setUser(user));
  } else {
    dispatch(clearUser());
  }
};



// Replace these with actual API calls or authentication logic
const LoginFunction = async (credentials) => {
  // successful login and return user data
  return { username: credentials.username };
};

const LogoutFunction = async () => {
  // successful logout
};

const AuthenticationCheckFunction = async () => {
  // checking if the user is authenticated and return user data if authenticated
  return { username: 'authenticatedUser' };
};

export default authSlice.reducer;
