import { Route, Routes } from "react-router-dom";
import { nav } from "./navigation";
import { useSelector } from "react-redux";
import RenderHeader from "./RenderHeader";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import PageNotFound from "../pages/Landing/PageNotFound";

const RenderRoutes = () => {
  // get user authentication status from authSlice
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const [inputCreds, setInputCreds] = React.useState({
    username: "",
    password: "",
  });
  const [showError, setShowError] = React.useState(false);
  const [isInternalUser, setIsInternalUser] = React.useState(
    localStorage.getItem("internalUser") &&
      localStorage.getItem("internalUser")?.length === 36 &&
      localStorage.getItem("internalUser")?.split("-")?.length === 5
      ? true
      : false
  );
  const [visible, setVisible] = React.useState(false);

  // SESSION STORE : (WARNING DEPRECATED: CAUSES A BUG WHERE USER IS UNABLE TO OPEN FILE SELECTOR in DragAndDropUploadResume, ProfileComponent, video-resume)
  // const sessionTime = useSelector((state) => state.session.sessionTime);
  // const currentDate = new Date();
  // useEffect(() => {
  //   const handleSession = () => {
  //     if (sessionTime) {
  //       const differenceInMs = currentDate - sessionTime;
  //       // const differenceInMinutes = differenceInMs / (1000 * 60);
  //       const differenceInDays = differenceInMs / (1000 * 60 * 60 * 24); // Convert milliseconds to days
  //       // console.log("Different  time in days:", differenceInDays);
  //       if (differenceInDays >= 60) {
  //         dispatch(clearUser());
  //         dispatch(clearSessionTime());
  //         navigate("/");
  //       }
  //     }
  //   };
  //   const eventListenerCallback = () => {
  //     handleSession();
  //   };
  //   const beforeUnloadCallback = (event) => {
  //     event.preventDefault();
  //     handleSession();
  //   };
  //   window.addEventListener("visibilitychange", eventListenerCallback);
  //   window.addEventListener("click", beforeUnloadCallback);
  //   return () => {
  //     window.removeEventListener("visibilitychange", eventListenerCallback);
  //     window.removeEventListener("click", beforeUnloadCallback);
  //   };
  // }, [dispatch, navigate, sessionTime]);

  // Returns from the funtion if the environemnt is staging. Fill in username and password to get access of avua.online
  if (
    import.meta.env.VITE_API_BASE_URL === "https://api.avua.online" &&
    !isInternalUser
  ) {
    const handleSubmit = () => {
      if (
        inputCreds.username === import.meta.env.VITE_STAGING_USER &&
        inputCreds.password === import.meta.env.VITE_STAGING_PASS
      ) {
        function generateUUID() {
          const uuid = uuidv4();
          return uuid;
        }
        localStorage.setItem("internalUser", generateUUID());
        setIsInternalUser(true);
      } else {
        setShowError(true);
      }
    };
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
      <div className="mt-[30px] md:mt-[50px] text-center font-mullish text-gray text-[20px] font-[600]">This is internal website. Please go to <a className="text-[blue] cursor-pointer mx-1" href="https://avua.com" target="">avua.com</a> to access the platform.</div>
      <div className="h-[100vh] flex flex-row justify-center md:justify-start md:flex-col md:items-center">
        <div className="mt-[20px]">
          <div className="flex flex-col md:flex-row">
            <input
              className="text-sm py-2 px-2 border border-solid border-gray-300 rounded"
              type="text"
              placeholder="username"
              onChange={(e) =>
                setInputCreds((prev) => ({ ...prev, username: e.target.value }))
              }
            />
            <div className="relative">
              <input
                type={visible ? "text" : "password"}
                className="text-sm py-2 px-2 border border-solid border-gray-300 rounded md:ml-3 mt-2 md:mt-0"
                placeholder="password"
                onChange={(e) =>
                  setInputCreds((prev) => ({
                    ...prev,
                    password: e.target.value,
                  }))
                }
              />
              <div onClick={() => setVisible(!visible)} className="absolute right-4 top-5 md:top-3 cursor-pointer">
                {visible ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
              </div>
            </div>
            <button className="px-5 py-2 ml-0 md:ml-3 rounded-lg bg-darkPurple text-white2 font-mullish text-[15px] mt-2 md:mt-0">
              ENTER
            </button>
          </div>
          <p className="flex justify-center mt-5 text-red font-nuniton text-[12px]">
            {showError &&
              "Access denied! This portal is only for the team avua"}
          </p>
        </div>
        </div>
      </form>
    );
  }

  return (
    <>
      <RenderHeader />
      <Routes>
        {nav.map((r, i) => {
          // Protected routes
          if (r.isPrivate === isAuthenticated) {
            return <Route key={i} path={r.path} element={r.element} />;
          }
          // Public Routes
          else if (!r.isPrivate) {
            return <Route key={i} path={r.path} element={r.element} />;
          } else return false;
        })}
         <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
};

export default RenderRoutes;
