import { createSlice } from '@reduxjs/toolkit';
import { getEmployerProfile, patchBasicProfile } from '../../api/Employer/employer';

const initialState = {
    first_name: "",
    last_name: "",
    designation: "",
    phone: "",
    email: "",
    profile_pic_path: "",
    company:"",
    logo:""
  };

  const employerProfileSlice = createSlice({
    name: 'employerProfileData',
    initialState,
    reducers: {
      setProfileData: (state, action) => {
        state.first_name = action.payload.first_name;
        state.last_name = action.payload.last_name;
        state.designation = action.payload.designation;
        state.phoneNumber = action.payload.phone;
        state.email = action.payload.email;
        state.profile_pic_path = action.payload.profile_pic_path;
        state.company = action.payload.company;
        state.logo = action.payload.logo;
      },
    },
  });

  export const {  setProfileData } = employerProfileSlice.actions;


  export const fetchProfileData = (token) => async (dispatch) => {
    // console.log('Employer profile data : ',profileData)
    try {
      const resData = await getEmployerProfile(token);
      if(resData?.data?.msg =="success"){
      dispatch(setProfileData(resData?.data?.data))
      return resData?.data?.data
    }
    } catch (error) {
      // Handle API call errors
      // dispatch(setError('Error Fetching Employer Profile Data'));
      console.log("Error Fetching Employer Profile Data")
      return error;
    }
  };

  export const updateProfileData = (profileData, token) => async (dispatch) => {
    console.log('Employer profile data : ',profileData)
    try {
      const resData = await patchBasicProfile(profileData, token);
      if(resData.data.msg === "success"){
        dispatch(fetchProfileData(token))
      }
    } catch (error) {
      // Handle API call errors
      console.log("Error Updating Employer Profile Data")
      return error;
    }
  };



export default employerProfileSlice.reducer;